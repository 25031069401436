const configuration = {
  'sch-local': {
    oscLink: 'http://localhost:4010',
  },
  'nord-local': {
    oscLink: 'http://localhost:4010',
  },
  'sch-development': {
    oscLink: 'https://sch-osc-sve-dev.fp-non-prod.cloud.netcetera.com',
  },
  'nord-development': {
    oscLink: 'https://nord-osc-sve-dev.fp-non-prod.cloud.netcetera.com',
  },
  'sch-staging': {
    oscLink: 'https://account-stage.schwaebische.de',
  },
  'nord-staging': {
    oscLink: 'https://account-stage.nordkurier.de',
  },
  'sch-production': {
    oscLink: 'https://account.schwaebische.de',
  },
  'nord-production': {
    oscLink: 'https://account.nordkurier.de',
  },
}

const environment = document.querySelector('meta[name="environment"]')?.content
const tenant = document.querySelector('meta[name="tenant"]')?.content

const config = configuration[`${tenant}-${environment}`]

function supportedBrowser() {
  if (typeof window.localStorage === 'undefined') {
    console.warn('localStorage is not supported by this browser.')
    return false
  }

  if (!navigator.cookieEnabled) {
    console.warn('Cookies are not supported by this browser.')
    return false
  }
  return true
}

function getFromLocalStorage(item) {
  var content = window.localStorage.getItem(item)
  var jsonContent
  try {
    jsonContent = JSON.parse(content)
  } catch (e) {
    jsonContent = content
  }
  return jsonContent
}

function getSessionCookie() {
  var match = document.cookie.match(/sso-sessionId=([a-z0-9]+)/)
  return match ? match[1] : ''
}

function setCookie(name, value, exdays) {
  const d = new Date()
  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
  const expires = `expires=${d.toUTCString()}`
  document.cookie = `${name}=${value};${expires};path=/`
}

const getUserData = async () => {
  let user = {
    account: null,
    session: null,
    entitlements: null,
  }
  if (!supportedBrowser()) {
    return
  }
  const fetchUser = async sessionCookie => {
    const userData = await fetch(
      `${config.oscLink}/api/session/${sessionCookie}/user`,
      {
        method: 'POST',
      },
    )
    const json = await userData.json()
    return json
  }
  const sessionCookie = getSessionCookie()
  if (!sessionCookie) {
    return user
  }
  const account = getFromLocalStorage('Account')
  const session = getFromLocalStorage('Session')
  const entitlements = getFromLocalStorage('Entitlements')
  const token = window.localStorage.getItem('Token')
  const reLoginHappened = !!token && token !== sessionCookie
  const initialFetch = !!sessionCookie && !account?.accountReference
  if (initialFetch || reLoginHappened) {
    try {
      const data = await fetchUser(sessionCookie)
      const account = data?.account
      const session = data?.session
      const entitlements = data?.entitlements
      const region = account?.customParameters?.Region
      const mppNextRegionOperation = !!account.customParameters.Region
        ? 'replace'
        : 'add'
      if (!!region) {
        window.localStorage.setItem('Region', region)
        setCookie('category', region, 30)
      }
      window.localStorage.setItem('mppNextRegOp', mppNextRegionOperation)
      window.localStorage.setItem('Account', JSON.stringify(account))
      window.localStorage.setItem('Session', JSON.stringify(session))
      window.localStorage.setItem('Entitlements', JSON.stringify(entitlements))
      window.localStorage.setItem('Token', getSessionCookie())
      window.dispatchEvent(new Event('login'))
      user = { account, session, entitlements }
    } catch (error) {
      console.error('Error fetching user data:', error)
    }
  } else {
    user = { account, session, entitlements }
  }

  return user
}

async function clientSideDataLayer() {
  if (!supportedBrowser()) {
    return
  }

  function setUserType(session, entitlements) {
    if (!session || !session.longSessionActive) {
      return 'anonymous user'
    }
    if (!entitlements || entitlements.length === 0) {
      return 'registered nonpaying user'
    }
    if (entitlements && entitlements.length > 0) {
      return 'paying user'
    }
    return null
  }

  function updateDataLayer(userId, sessionActive, userType) {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      user_id: userId || null,
      smd_eingeloggt: sessionActive || false,
      smd_abotyp: userType,
      ld_subscriber: userType === 'paying user' ? '1' : '0',
      ld_logged_in: sessionActive ? '1' : '0',
    })
  }

  if (window?.fpuser?.data) {
    const { account, session, entitlements } = await window?.fpuser?.data
    const userType = setUserType(session, entitlements)
    updateDataLayer(
      account ? account.clientUserId : null,
      session ? session.longSessionActive : false,
      userType,
    )
  }
}

window.fpuser = {
  clientSideDataLayer,
  data: getUserData().finally(() => {
    window.dispatchEvent(new Event('pushAuthData'))
  }),
}
